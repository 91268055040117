import { lockBody, unlockBody } from "./dom";

/**
 * This event is triggered by Google Tagmanager when CMP is loaded and ready to use.
 */
window['consentManagerProviderReady'] = new Event('consentManagerProviderReady');

const consentManagerInstance = new Promise<ConsentManagerProvider | null>(resolve => {
    document.addEventListener(
        'consentManagerProviderReady',
        () => {
            if (window.hasOwnProperty('__cmp') && typeof window['__cmp'] === 'function') {
                lockBodyOnFirstConsentScreen();
                resolve(window['__cmp']);
            } else {
                resolve(null);
            }
        },
    );
});

function lockBodyOnFirstConsentScreen() {
    let isBodyLocked = false;
    const status = window.__cmp!('consentStatus');

    if (status && !status.userChoiceExists) {
        isBodyLocked = true;
        lockBody();
    }

    if (isBodyLocked) {
        window.__cmp!('addEventListener',
            [
                'consentscreenoff',
                function(): void {
                    unlockBody();
                },
                false,
            ],
        );
    }
}

/**
 * {@link https://help.consentmanager.net/books/cmp/page/javascript-api}
 */
type ConsentManagerProvider = (
    command: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parameter?: any,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    callback?: Function,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any;

export type ConsentChangeCallback = (value: boolean) => void;

export enum ConsentManagerVendors {
    GOOGLE_GENERAL = 's135',
}

function isConsentGranted(vendorId: string, cmpManager: ConsentManagerProvider): boolean {
    const cmpData = cmpManager('getCMPData');

    return typeof cmpData === 'object' &&
        'vendorConsents' in cmpData &&
        vendorId in cmpData.vendorConsents &&
        cmpData.vendorConsents[vendorId];
}

export function getConsentByVendorId(vendorId: string) {
    return new Promise<boolean>(resolve => {
        consentManagerInstance.then((cmpManager) => {
            if (cmpManager === null) {
                resolve(false);
                return;
            }
            resolve(isConsentGranted(vendorId, cmpManager));
        });
    });
}

export function watchConsentChange(vendorId: string, callback: ConsentChangeCallback) {
    consentManagerInstance.then((cmpManager) => {
        if (cmpManager === null) {
            return;
        }
        cmpManager(
            'addEventListener',
            [
                'consent',
                function(): void {
                    callback(isConsentGranted(vendorId, cmpManager));
                },
                false,
            ],
        );
    });
}
